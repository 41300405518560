/* SimplePictureRecommendation.css */
.picture-recommendation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Changed from min-height: 100vh to height: 50vh */
  background-color: #fcfbf7;
  font-family: Arial, sans-serif;
}

.picture-recommendation-card {
  background-color: #ffffff;
  border-radius: 12px; /* Increased from 8px for a slightly softer look */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 3rem; /* Increased from 2rem */
  max-width: 500px; /* Increased from 400px */
  width: 90%;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.picture-recommendation-card:hover {
  transform: translateY(-5px);
}

.picture-recommendation-title {
  color: #1a202c;
  font-size: 2rem; /* Increased from 1.5rem */
  font-weight: bold;
  margin-bottom: 1.5rem; /* Increased from 1rem */
}

.picture-recommendation-text {
  color: #4a5568;
  font-size: 1.25rem; /* Increased from 1rem */
  line-height: 1.6;
  margin-bottom: 2rem; /* Increased from 1.5rem */
}

.picture-recommendation-email {
  display: inline-block;
  color: #3182ce;
  font-size: 1.4rem; /* Increased from 1.1rem */
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.picture-recommendation-email:hover {
  color: #2c5282;
  text-decoration: underline;
}

@media (max-width: 600px) { /* Increased from 480px for better responsiveness */
  .picture-recommendation-card {
    padding: 2rem; /* Increased from 1.5rem */
  }
  
  .picture-recommendation-title {
    font-size: 1.75rem; /* Increased from 1.25rem */
  }
  
  .picture-recommendation-text {
    font-size: 1.1rem; /* Increased from 0.9rem */
  }

  .picture-recommendation-email {
    font-size: 1.2rem; /* Increased from 0.9rem */
  }
}