/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #fcfbf7;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #F28C28;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
}

.button:hover {
  background-color: #FFAC1C;
}