.travel {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
  }
  
  .travel h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .travel section {
    margin-bottom: 3rem;
  }
  
  .travel h2 {
    border-bottom: 2px solid #F28C28;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .hotel, .transport-option {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .hotel h3, .transport-option h3 {
    color: #F28C28;
    margin-bottom: 0.5rem;
  }

  .attractions ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .attractions li {
    background-color: #f9f9f9;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .travel {
      padding: 1rem;
    }
  }