/* Home content styles */
.home {
  text-align: center;
  padding: 1rem 0;
}

.home h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
}

.home-content .couple-names {
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;  
  font-size: 3.5rem;
  color: #F28C28;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.home-content .couple-names .name {
  font-size: 1.2em;  /* 20% larger than the parent font-size */
  font-weight: bold;
}

.home-content .couple-names .ampersand {
  font-size: 0.7em;  /* 30% smaller than the parent font-size */
  vertical-align: middle;
  margin: 0 0.1em;  /* Add a small space around the ampersand */
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0rem;
  align-items: stretch;
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.wedding-details {
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif; /* TODO(Amy): Verify uniform font for titles and uniform for paragraphs */
  background-color: #f9f9f9; /* TODO(Amy): Choose Background Color of Cards */
  border-radius: 8px;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.wedding-details p {
  margin-bottom: 0.5rem;
  color: #F28C28;  
}

@media (max-width: 968px) {
  .home-content {
    flex-direction: column;
  }

  .hero-image, .text-content {
    flex: none;
    width: 100%;
  }

  .hero-image img {
    max-height: 400px;
  }

  .text-content {
    text-align: center;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .header .couple-names {
    font-size: 2rem;
  }

  .header nav {
    padding: 0.3rem;
  }

  .header a {
    font-size: 0.8rem;
  }
}
