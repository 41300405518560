.schedule {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.schedule h2 {
  text-align: center;
  color: #F28C28;
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.info-box {
  background-color: #E5E7EB;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.info-box strong {
  display: block;
  margin-bottom: 0.5rem;
}

.schedule h3 {
  text-align: center;
  color: #666;
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.schedule-list {
  list-style-type: none;
  padding-left: 0;
}

.schedule-item {
  margin-bottom: 1rem;
  border-left: 4px solid #93C5FD;
  padding-left: 1rem;
}

.schedule-item-header {
  font-weight: 700;
  font-size: 1.125rem;
}

.schedule-item-content {
  padding-left: 1.5rem;
  font-weight: normal;
  color: #666;
}

.schedule-sublist {
  margin-top: 0.5rem;
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #666;
}

.schedule-sublist li {
  margin-bottom: 0.25rem;
}

@media (max-width: 600px) {
  .schedule {
    padding: 1rem;
  }
  
  .schedule-item-content,
  .schedule-sublist {
    padding-left: 1rem;
  }
}