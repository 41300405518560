.photos-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .photos-page h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #F28C28;
  }
  
  .photo-section-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .photo-section-toggle button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .photo-section-toggle button.active {
    background-color: #F28C28;
    color: white;
  }
  
  .photo-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .photo-item {
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .photo-item:hover {
    transform: scale(1.05);
  }
  
  .photo-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .photos-page {
      padding: 1rem;
    }
  
    .photo-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }