.header {
  position: relative;
  background-image: url(/public/images/home_banner.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .couple-names {
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  font-size: 2.5rem;
  color: #F28C28;
  margin: 0.5rem 0;
  text-align: center;
  width: 100%;
}

.header .couple-names .name {
  font-size: 1.2em;
  font-weight: bold;
}

.header .couple-names .ampersand {
  font-size: 0.7em;
  vertical-align: middle;
  margin: 0 0.1em;
}

.hamburger-menu {
  background: none;
  border: none;
  color: #F28C28;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  padding: 0.5rem;
}

.header nav {
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
}

.header nav.hidden {
  display: none;
}

.header ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.header li {
  margin: 0 0.5rem;
}

.header a {
  text-decoration: none;
  color: #F28C28;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

.header a:hover {
  color: #8A9A5B;
}

@media (max-width: 968px) {
  .header .couple-names {
    font-size: 2rem;
  }

  .header nav {
    padding: 0.3rem;
  }

  .header a {
    font-size: 0.8rem;
  }

  .header-content {
    align-items: center;
  }

  .header nav {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 3;
  }

  .header ul {
    flex-direction: column;
    align-items: center;
  }

  .header li {
    margin: 0.5rem 0;
  }
}