.things-to-do {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
  }
  
  .things-to-do h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .intro {
    text-align: center;
    margin-bottom: 2rem;
    font-style: italic;
  }
  
  .category {
    margin-bottom: 3rem;
  }
  
  .category h2 {
    border-bottom: 2px solid #F28C28;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .activities {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .activity {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .activity h3 {
    color: #F28C28;
    margin-bottom: 0.5rem;
  }
  
  .details {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0.5rem;
  }
  
  .details li {
    margin-bottom: 0.25rem;
  }
  
  .recommendations {
    background-color: #fcfbf7;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 2rem;
  }
  
  .recommendations h2 {
    color: #F28C28;
    margin-bottom: 1rem;
  }
  
  .recommendations ul {
    padding-left: 1.5rem;
  }
  
  .recommendations li {
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .things-to-do {
      padding: 1rem;
    }
    
    .activities {
      grid-template-columns: 1fr;
    }
  }