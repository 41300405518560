/* RSVP Styles */
.rsvp {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem 0;
}

.deadline-banner {
  background-color: #F28C28;
  color: white;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-weight: bold;
}

.rsvp h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #F28C28;
}

.rsvp form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: bold;
}

.rsvp input[type="text"],
.rsvp input[type="email"],
.rsvp input[type="number"],
.rsvp select,
.rsvp textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.rsvp select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 12 12'%3E%3Cpath d='M10.293 3.293 6 7.586 1.707 3.293A1 1 0 0 0 .293 4.707l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 1 0-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  padding-right: 2.5rem;
}

.rsvp select option:first-child {
  color: #777;
  /* Lighter shade of black for placeholder */
}

.rsvp select:invalid,
.rsvp select option[value=""] {
  color: #777;
}

.rsvp textarea {
  min-height: 100px;
  resize: vertical;
}

.rsvp button {
  align-self: flex-start;
  padding: 0.5rem 1rem;
  background-color: #F28C28;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
}

.rsvp button:hover {
  background-color: #FFAC1C;
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.guest-count-input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.guest-count-input {
  width: 60px;
  text-align: center;
  -moz-appearance: textfield;
}

.guest-count-input::-webkit-outer-spin-button,
.guest-count-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.guest-count-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F28C28;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.guest-count-button:hover {
  background-color: #FFAC1C;
}

.additional-guest {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
}

.additional-guest h3 {
  color: #F28C28;
  margin-bottom: 1rem;
}

.submit-button {
  align-self: stretch;
  padding: 0.75rem 1.5rem;
  background-color: #F28C28;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.submit-button:hover {
  background-color: #FFAC1C;
}

.attending-group {
  margin-bottom: 1rem;
}

.attending-checkboxes {
  display: flex;
  gap: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .rsvp h2 {
    font-size: 1.75rem;
  }

  .form-group label {
    font-size: 1rem;
  }

  .rsvp input[type="text"],
  .rsvp input[type="email"],
  .rsvp input[type="number"],
  .rsvp select,
  .rsvp textarea {
    font-size: 16px;
    /* Prevents zoom on mobile */
    padding: 0.5rem;
  }

  .rsvp select {
    color: #333;
    /* Ensure text color is dark for selected options on mobile */
  }

  .rsvp select option:first-child,
  .rsvp select:invalid,
  .rsvp select option[value=""] {
    color: #777;
    /* Ensure placeholder text remains light on mobile */
  }

  .guest-count-input-container {
    justify-content: center;
  }

  .guest-count-input {
    width: 80px;
    height: 40px;
    font-size: 1.2rem;
  }

  .guest-count-button {
    width: 40px;
    height: 40px;
  }

  .checkbox-label input[type="checkbox"] {
    width: 24px;
    height: 24px;
  }

  .submit-button {
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
}