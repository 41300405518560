.our-story {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .our-story h1 {
    text-align: center;
    margin-bottom: 1rem;
    color: #F28C28;
    font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  }
  
  .our-story .intro {
    text-align: center;
    font-style: italic;
    margin-bottom: 2rem;
    font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  }
  
  .timeline {
    position: relative;
    padding: 2rem 0;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background: #F28C28;
    transform: translateX(-50%);
  }
  
  .event {
    position: relative;
    margin-bottom: 2rem;
    width: 50%;
  }
  
  .event::after {
    content: '';
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    background: #F28C28;
    border-radius: 50%;
  }
  
  .event.left {
    left: 0;
    padding-right: 2rem;
  }
  
  .event.right {
    left: 50%;
    padding-left: 2rem;
  }
  
  .event.left::after {
    right: -8px;
  }
  
  .event.right::after {
    left: -8px;
  }
  
  .event-content {
    padding: 1rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  }
  
  .event-date {
    font-weight: bold;
    color: #F28C28;
    margin-bottom: 0.5rem;
    font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  }
  
  .event-content h3 {
    margin-bottom: 0.5rem;
    font-family: 'Avantgarde', 'Century Gothic', 'CenturyGothic', 'AppleGothic', sans-serif;
  }
  
  @media (max-width: 768px) {
    .timeline::before {
      left: 0;
    }
  
    .event {
      width: 100%;
      padding-left: 2rem;
    }
  
    .event.left, .event.right {
      left: 0;
      padding-right: 0;
    }
  
    .event.left::after, .event.right::after {
      left: -8px;
    }
  }
