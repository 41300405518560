.footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #fcfbf7;
  margin-top: 2rem;
}

.social-links {
  margin-top: 1rem;
}

.social-links a {
  margin: 0 0.5rem;
  color: #333;
  font-size: 1.5rem;
}